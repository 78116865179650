import { Service } from "./service";

export class CustomerService extends Service {
  static async getCustomer(accessToken: string, getCustomerReq: API.GetCustomerReq): Promise<API.GetCustomerResp> {
    try {
      const response = await CustomerService.post<API.GetCustomerReq, API.GetCustomerResp>("/customer/get-profile", getCustomerReq, accessToken);
      if (response?.data?.data?.success && response?.data?.data?.customer) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting customer");
      }
    } catch (err) {
      throw CustomerService.errorHandler(err);
    }
  }
  static async getMixpanel(accessToken: string, getCustomerReq: API.GetCustomerReq): Promise<API.GetMixpanelResp> {
    try {
      const response = await CustomerService.post<API.GetCustomerReq, API.GetMixpanelResp>("/customer/mixpanel", getCustomerReq, accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting customer");
      }
    } catch (err) {
      throw CustomerService.errorHandler(err);
    }
  }
  static async initPasswordReset(accessToken: string, initPasswordResetData: API.InitPasswordResetReq): Promise<API.InitPasswordResetResp> {
    try {
      const response = await CustomerService.post<API.InitPasswordResetReq, API.InitPasswordResetResp>("/customer/init-password-reset", initPasswordResetData , accessToken);
      if (response?.data?.data?.success) {
        return response?.data.data;
      } else {
        throw new Error("Error password reset");
      }
    } catch (err) {
      throw CustomerService.errorHandler(err);
    }
  }
  static async activate(accessToken: string, activateReq: API.CutomerActivateReq): Promise<API.CustomerActivateResp> {
    try {
      const response = await CustomerService.post<API.CutomerActivateReq, API.CustomerActivateResp>("/customer/activate", activateReq , accessToken);
      if (response?.data?.data?.success) {
        return response?.data.data;
      } else {
        throw new Error("Error activating customer");
      }
    } catch (err) {
      throw CustomerService.errorHandler(err);
    }
  }
  static async getChartMogul(accessToken: string, getCustomerReq: API.GetCustomerReq): Promise<API.GetChartMogulResp> {
    try {
      const response = await CustomerService.post<API.GetCustomerReq, API.GetChartMogulResp>("/customer/chartmogul", getCustomerReq, accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting customer");
      }
    } catch (err) {
      throw CustomerService.errorHandler(err);
    }
  }
  static async applyDeal(accessToken: string, applyDealReq: API.ApplyDealReq): Promise<API.ApplyDealResp> {
    try {
      const response = await CustomerService.post<API.ApplyDealReq, API.ApplyDealResp>("/customer/deal-apply", applyDealReq, accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting customer");
      }
    } catch (err) {
      throw CustomerService.errorHandler(err);
    }
  }
  static async createCustomer(accessToken: string, createCustomerReq: API.CreateAccount): Promise<API.CreateCustomerResp> {
    try {
      const response = await CustomerService.post<API.CreateAccount, API.CreateCustomerResp>("/customer/create", createCustomerReq, accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting customer");
      }
    } catch (err) {
      throw CustomerService.errorHandler(err);
    }
  }
  static async createSubscription(accessToken: string, createPaymentIntentReq: API.PaymentIntentReq): Promise<API.CreateSubscriptionResp> {
    try {
      const response = await CustomerService.post<API.PaymentIntentReq, API.CreateSubscriptionResp>("/customer/subscription/create", createPaymentIntentReq, accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting customer");
      }
    } catch (err) {
      throw CustomerService.errorHandler(err);
    }
  }
}