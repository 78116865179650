import { UserService } from '../services/user';
import { defineStore } from 'pinia';
import { useAuthStore, getAccessToken } from './auth';
import { SignupService } from '@/services/signup';
import { ApiService } from '@/services/api';

export interface UserState {
    profile: API.UserProfile | null;
    subscription: SubscriptionFormatted | null;
    billingInfo: API.StripeUserBillingInfo | null;
}

export interface SubscriptionFormatted extends API.Subscription {
    isTrial?: boolean | null;
    daysRemaining?: number;
    currentPeriodEndDate?: Date;
    endFormatted?: string;
    totalUnitAmount?: number;
    scheduledSubscription: SubscriptionFormatted | null;
}

export const useUserStore = defineStore('user', {
    state: (): UserState => {
        return {
            profile: null,
            subscription: null,
            billingInfo: null
        }
    },
    actions: {
        async updateUserdata() {
            const response = await UserService.getUserdata(getAccessToken());
            if (response.success && response.userdata) {
                this.setAll(response.userdata);
            }
            return response;
        },
        
        async activateTrial() {
          try {
            const response = await SignupService.activateTrial(getAccessToken());
            if(response.subscription) this.setSubscription(response.subscription);
          } catch (err) {
              console.error(err);
              throw err;
          }
      },
        async verifyEmail(verifyEmailData: API.VerifyEmailReq) { //todo check usage
            return await SignupService.verifyEmail(getAccessToken(), verifyEmailData);
        },
        async resendEmailOtp(resendEmailOtpData: API.ResendEmailOtpReq) { //todo check usage
            return await SignupService.resendEmailOtp(getAccessToken(), resendEmailOtpData);
        },
        async changeEmail(changeEmailData: API.ChangeEmailReq) {
            const response = await SignupService.changeEmail(getAccessToken(), changeEmailData);
            if (response.success && response.accessToken) {
                const auth = useAuthStore();
                auth.setToken(response.accessToken);
            }
            return response;
        },
        async setPhone(setPhoneData: API.PhoneSetupReq) {
            return await SignupService.setPhone(getAccessToken(), setPhoneData);
        },
        async verifySmsOtp(verifySmsOtpData: API.VerifySmsOtpReq) {
            return await SignupService.verifySmsOtp(getAccessToken(), verifySmsOtpData);
        },
        async resendSmsOtp(resendSmsOtpData: API.ResendSmsOtpReq) {
            return await SignupService.resendSmsOtp(getAccessToken(), resendSmsOtpData);
        },
        async initPasswordReset(initPasswordResetData: API.InitPasswordResetReq) {
            return await UserService.initPasswordReset(initPasswordResetData);
        },
        async passwordReset(passwordResetData: API.PasswordResetReq) {
            return await UserService.passwordReset(passwordResetData);
        },
        async updateCard(updateCardReq: API.UpdateCardReq) {
            try {
                const billingInfo = await ApiService.updateCard(getAccessToken(), updateCardReq);
                this.setBillingInfo({...this.billingInfo , ...updateCardReq} );
            } catch (err) {
                console.error(err);
                throw err;
            }
        },

        //setters
        setProfile(profile: API.UserProfile | null) {
            this.profile = profile;
        },
        setSubscription(subscription: API.Subscription | null) {
            if (!subscription) {
                this.subscription = null;
                return;
            }
            this.subscription = prepareSuscription(subscription);
        },
        setBillingInfo(billingInfo: API.StripeUserBillingInfo | null) {
            this.billingInfo = billingInfo;
        },
        setAll(user: API.UserData) {
            this.profile = user.profile;
        },
        clearAll() {
            this.profile = null;
            this.subscription = null;
            this.billingInfo = null;
        }

    }
});

function prepareSuscription(sub: API.Subscription | null): SubscriptionFormatted | null {
    if (!sub) {
        return null;
    }
    const prepared: SubscriptionFormatted = {
        isTrial: sub.plan.code?.startsWith("trial"),
        scheduledSubscription: null,
        ...sub
    };

    if (sub.currentPeriodEndsAt || sub?.scheduledChange?.currentPeriodStartsAt) {
      const endPeriod = sub.plan.name === 'Trial' && sub.scheduledChange ? sub.scheduledChange.currentPeriodStartsAt : sub.currentPeriodEndsAt;
      prepared.currentPeriodEndDate = new Date(endPeriod as number + 1000);

      const parts = endDateFormat.formatToParts(prepared.currentPeriodEndDate);
        prepared.endFormatted = `${parts.find(p => p.type === "month")?.value} ${parts.find(p => p.type === "day")?.value}, ${parts.find(p => p.type === "year")?.value}`;
    }

    if (prepared.unitAmount) {
        prepared.totalUnitAmount = prepared.unitAmount;
    }

    if (sub.state != "expired" && prepared.currentPeriodEndDate) {
        prepared.daysRemaining = Math.ceil((prepared.currentPeriodEndDate.getTime() - (new Date()).getTime()) / (1000 * 60 * 60 * 24));
    }

    if (sub.scheduledChange) {
      prepared.scheduledSubscription = prepareSuscription(sub.scheduledChange);
    }

    return prepared;
}

const endDateFormat = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
});