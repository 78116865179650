import { createWebHistory, createRouter } from "vue-router";
import LoginForm from "./components/signup/LoginForm.vue";
import MainDashboard from "./components/dashboard/MainDashboard.vue";
import DashNavigation from "./components/dashboard/DashNavigation.vue";
import CreateCustomer from "./components/customer/CreateCustomer.vue";
import CustomerProfile from "./components/customer/CustomerProfile.vue";
import SearchResults from "./components/dashboard/SearchResults.vue";
import ReportDashboard from "./components/dashboard/ReportDashboard.vue";
import AuditLog from "./components/reports/AuditLog.vue";
import DealReports from "./components/reports/DealReport.vue";
import AccountReports from "./components/reports/AccountReport.vue";
import ActivityReport from "./components/reports/ActivityReport.vue";
import ReportType from "./components/reports/ReportType.vue";
import CreateDeals from "./components/dashboard/CreateDeals.vue";
import ManageUser from "./components/dashboard/ManageUser.vue";

import { useAuthStore } from "./store/auth";
import { useUserStore } from "./store/user";
import { useCustomerStore } from "./store/customer";
import CurrentActivityReport from "./components/reports/CurrentActivityReport.vue";

const publicPages = new Set(["/login"]);
const publicParametrizedPages = [
  /\/password-reset\/.*$/ , 
  /^\/customers\/(stripe|recurly|chargebee)\/.*$/
];
const stateRestrictedPages = new Set<string>([]);
const roleRestrictedPages: Record<string , string[]> = {
  '/reports': ['admin']
}
const privatePages = new Set([
  ...stateRestrictedPages,
  "/dashboard",
  "/dashboard/users/manage",
  "/dashboard/customers/create",
  "/dashboard/search/results",
  "/dashboard/deals/manage",
  "/reports",
  "/reports/audit-log",
  "/reports/deal-reports",
  "/reports/account-reports",
  "/reports/active-user-reports",
  "/reports/current-active-user-reports"
]);
const allPages = new Set([...privatePages, ...publicPages]);

const routes = [
  {
    name: "Root",
    path: "/",
    component: LoginForm
  },
  {
    name: "Login",
    path: "/login",
    component: LoginForm,
  },
  {
    name: "Report",
    path: "/reports/",
    component: ReportDashboard,
    children: [
      { path: "", component: ReportType },
      { path: "audit-log", name:"AuditLog", component: AuditLog },
      { path: "deal-reports", name:"DealReports", component: DealReports },
      { path: "account-reports", name:"AccountReports", component: AccountReports },
      { path: "active-user-reports", name:"ActivityReport", component: ActivityReport },
      { path: "current-active-user-reports", name:"CurrentActivityReport", component: CurrentActivityReport },
    ]
  },
  {
    name: "CustomerProfile",
    path: "/customers/:processor/:customerId",
    component: CustomerProfile,
    props: true
  },
  {
    name: "Dashboard",
    path: "/dashboard/",
    component: MainDashboard,
    children: [
      { path: "", component: DashNavigation },
      { path: "customers/create", name:"CreateCustomer", component: CreateCustomer },
      { path: "users/manage", name:"ManageUser", component: ManageUser },
      { path: "search/results", name:"SearchResults", component: SearchResults },
      { path: "deals/manage", name:"CreateDeals", component: CreateDeals },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  let validPage = allPages.has(to.path);
  let authRequired = privatePages.has(to.path);
  if (!validPage) {
    if (publicParametrizedPages.find(p => p.test(to.path))) {
      validPage = true;
      authRequired = true;
    }
  }
  const user = useUserStore();
  const auth = useAuthStore();
  const customer = useCustomerStore();
  let loggedIn = false;
  const userRole = user?.profile?.role;
  if (auth?.accessToken && userRole) {
    loggedIn = true;
    customer.resetCustomer();
  }

  // console.log("before each", to.path);
  if (validPage) {
    // console.log("valid page");
    if (authRequired) {
      // console.log("auth required page");
      if (loggedIn) {
        // console.log("logged in");

        // console.log("activationState", activationState);
        if(userRole) {
          if (roleRestrictedPages[to.path] && !roleRestrictedPages[to.path].includes(userRole)) {
            // console.log("prevent state restricted page");
            next("/dashboard");
          } else {
            // console.log("just next");
            next();
          }
        }
      } else {
        next("/login");
      }
    } else {
      // console.log("no auth required page");
      if (loggedIn) {
        // console.log("redirect dashboard");
        next("/dashboard");
      } else {
        // console.log("just next");
        next();
      }
    }
  } else {
    // console.log("invalid page");
    if (loggedIn) {
      // console.log("redirect dashboard");
      next("/dashboard");
    } else {
      // console.log("redirect login");
      next("/login");
    }
  }
});

export { router };