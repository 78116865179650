import { defineStore } from 'pinia';
import { ConfigService } from "@/services/config";
import { CheckoutService } from '@/services/checkout';
import { getAccessToken } from './auth';
import { ApiService } from '@/services/api';

export interface ConfigState {
    paymentSecret: string | null;
    setupSecret: string | null;
    searchResults: API.SearchResultCustomer[];
    dealList: API.Deal[];
    userList: API.UserProfile[];
    stripe?: {
      payment: {
          publicKey: string;
          account: string;
          apiVersion: string;
      }
  }
}

export const useConfigStore = defineStore('config', {
    state: (): ConfigState => {
        return {
            paymentSecret: null,
            setupSecret: null,
            searchResults: [],
            dealList: [],
            userList: []
        };
    },
    actions: {
        async getConfig() {
            try {
                const config = await ConfigService.getConfig();

                this.stripe = config.stripe;
            } catch (err) {
                console.error("Error getting app config", err);
            }
        },
        // async createPaymentIntent(createPaymentIntentReq: API.CreatePaymentIntentReq) {
        //   try {
        //     const subscription = await CheckoutService.createPaymentIntent(getAccessToken(), createPaymentIntentReq);
        //     this.setPaymentSecret(subscription.clientSecret);
        //     this.subscriptionId = subscription.subscriptionId;
        //   } catch (err) {
        //       console.error(err);
        //       throw err;
        //   }
        // },
        async search(searchReq: API.SearchReq) {
          try {
            const results = await ApiService.search(getAccessToken(), searchReq);
            if(results) this.searchResults = results;
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        async createDeal(dealReq: API.DealReq) {
          try {
            const success = await ApiService.createDeal(getAccessToken(), dealReq);
            if(success?.deal) this.dealList.push(success.deal);
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        async createUser(registerReq: API.RegisterReq) {
          try {
            return await ApiService.createUser(getAccessToken(), registerReq);
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        async getUsers() {
          try {
            const userList = await ApiService.getUsers(getAccessToken());
            if(userList.users) this.userList = userList.users;
            return userList;
          } catch (err) {
            console.error(err);
            throw err;
          }
        },
        async getReport(reportType: string | null, reportReq: API.ReportReq | null) {
          try {
            return await ApiService.getReport(getAccessToken() , reportType , reportReq);
          } catch (err) {
            console.error(err);
            throw err;
          }
        },
        async getUserActivity(reportReq: API.ActivityReportReq) {
          try {
            return await ApiService.getUserActivity(getAccessToken() , reportReq);
          } catch (err) {
            console.error(err);
            throw err;
          }
        },
        async excludeUsers(excludeReq: API.ExcludeReq) {
          try {
            return await ApiService.excludeUsers(getAccessToken() , excludeReq);
          } catch (err) {
            console.error(err);
            throw err;
          }
        },
        async getDeals() {
          try {
            const dealList = await ApiService.getDeals(getAccessToken());
            if(dealList.success && dealList.deals) this.dealList = dealList.deals;
            console.log(this.dealList);
          } catch (err) {
            console.error(err);
            throw err;
          }
        },
        setPaymentSecret(paymentSecret: string | null) {
          if(!paymentSecret) {
            this.paymentSecret = null;
            return;
          }
          this.paymentSecret = paymentSecret;
        },
        setSetupSecret(setupSecret: string | null) {
          if(!setupSecret) {
            this.setupSecret = null;
            return;
          }
          this.setupSecret = setupSecret;
        }
    },
    getters: {
        // planCost(state) {
        //     return (planCode: string) => {
        //         const plan = state.plansMap.get(planCode);
        //         return plan?.unitAmount ? plan.unitAmount : 0;
        //     }
        // },
        // planCostFormatted() {
        //     const planCost = this.planCost;
        //     return (planCode: string, precision: number = 0) => {
        //         return `$${(planCost(planCode) / 100).toFixed(precision)}`;
        //     }
        // },
        addonCost(state) {
            return (addon: API.Addon, planCode?: string | null) => {
                if (!addon || !planCode) {
                    return 0;
                } else {
                    return addon.unitAmount;
                }
            }
        },
        addonCostFormatted() {
            const addonCost = this.addonCost;
            return (addon: API.Addon, planCode?: string | null, precision: number = 0) => {
                return `$${(addonCost(addon, planCode) / 100).toFixed(precision)}`;
            }
        }
    }
})

const comparePlans = (a: API.Plan, b: API.Plan) => {
    if (a.name < b.name) {
        return 1;
    }
    if (a.name > b.name) {
        return -1;
    }
    return 0;
}