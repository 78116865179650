import { Ref } from "vue";
import RecaptchaBlock from "../signup/RecaptchaBlock.vue";

export interface States {
    loadingShow: Ref<boolean>;
    errorMessage: Ref<string>;
}

export async function submitHandler<T = void>(
    actionHandler: () => Promise<T>,
    states: States,
    successHandler?: (response: T) => Promise<any>,
    errorHandler?: (err: unknown) => Promise<void>
): Promise<void> {
    try {
        states.errorMessage.value = "";

        states.loadingShow.value = true;
        try {
            const response: T = await actionHandler();
            if (successHandler) {
                await successHandler(response);
            }
        } catch (err) {
            if (errorHandler) {
                await errorHandler(err);
            } else {
                if (err instanceof Error) {
                    states.errorMessage.value = err.message;
                }
            }
        }
        states.loadingShow.value = false;
    } catch (err) {
        console.error(err);
    }
}