import { defineStore } from 'pinia';
import { useAuthStore, getAccessToken } from './auth';
import { CustomerService } from '@/services/customer';

export interface CustomerState {
  profile: API.RecurlyUserProfile | API.StripeUserProfile | null;
  processor: API.PaymentProcessorData | null;
  mixpanel: API.MixpanelData | null;
  chartmogul: API.ChartMogulData | null;
}

export const useCustomerStore = defineStore('customer', {
    state: (): CustomerState => {
        return {
          profile: null,
          processor: null,
          mixpanel: null,
          chartmogul: null
        }
    },
    actions: {
        async getCustomer(getCustomerReq: API.GetCustomerReq) {
            try {
                const customerInfo = await CustomerService.getCustomer(getAccessToken(), getCustomerReq);
                if(customerInfo?.success) this.setCustomer(customerInfo);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async initPasswordReset(initPasswordResetData: API.InitPasswordResetReq) {
          return await CustomerService.initPasswordReset(getAccessToken(), initPasswordResetData);
        },
        async activate(activateReq: API.CutomerActivateReq) {
          return await CustomerService.activate(getAccessToken(), activateReq);
        },
        async getChartMogul(getCustomerReq: API.GetCustomerReq) {
          try {
              const chartmogulData = await CustomerService.getChartMogul(getAccessToken(), getCustomerReq);
              if(chartmogulData?.success && chartmogulData?.chartmogul) this.setChartMogul(chartmogulData.chartmogul);
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        async getMixpanel(getCustomerReq: API.GetCustomerReq) {
          try {
              const mixpanelData = await CustomerService.getMixpanel(getAccessToken(), getCustomerReq);
              if(mixpanelData?.success && mixpanelData?.mixpanel) this.setMixpanel(mixpanelData.mixpanel);
          } catch (err) {
              console.error(err);
              throw err;
          }
      },
      async createCustomer(createCustomerReq: API.CreateAccount) {
        try {
          return await CustomerService.createCustomer(getAccessToken(), createCustomerReq);
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      async applyDeal(applyDealReq: API.ApplyDealReq) {
        try {
          return await CustomerService.applyDeal(getAccessToken(), applyDealReq);
        } catch (err) {
            console.error(err);
            throw err;
        }
      },
      async createSubscription(createPaymentIntentReq: API.PaymentIntentReq) {
        try {
          return await CustomerService.createSubscription(getAccessToken(), createPaymentIntentReq);
        } catch (err) {
          console.error(err);
          throw err;
        }
      },

        //setters
        setChartMogul(chartmogulData: API.ChartMogulData | null) {
          this.chartmogul = chartmogulData;
        },
        setMixpanel(mixpanelData: API.MixpanelData | null) {
          this.mixpanel = mixpanelData;
        },
        resetCustomer() {
          this.profile = null;
          this.processor = null;
          this.mixpanel = null;
          this.chartmogul = null;
        },
        setCustomer(customerInfo: API.CustomerData | null) {
          if(customerInfo?.customer) {
            this.profile = customerInfo.customer.profile;
            this.processor = customerInfo.customer.processor;       
          }
        },

    }
});