<template>
  <div class="flex flex-col text-center items-center h-screen p-4 md:p-0">
    <div class="flex justify-center pt-6 mt-6">
      <div class="flex flex-col">
      </div>
      <div class="flex flex-col items-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>