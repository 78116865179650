import { Service } from "./service";

export class ApiService extends Service {
  static async search(accessToken: string , searchParams: API.SearchReq) {
    try {
      const response = await ApiService.post<API.SearchReq, API.SearchResp>("/api/search", searchParams , accessToken);
      if (response?.data?.data?.success && response?.data?.data?.results) {
        return response.data.data.results;
      } else {
        throw new Error("Incorrect search parameters");
      }
    } catch (err) {
      ApiService.errorHandler(err);
    }
  }
  static async getUsers(accessToken: string): Promise<API.GetUsersResp> {
    try {
      const response = await ApiService.get<API.GetUsersResp>("/api/users", accessToken);
      if (response?.data?.data?.success && response?.data?.data?.users) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting customer");
      }
    } catch (err) {
      throw ApiService.errorHandler(err);
    }
  }
  static async getDeals(accessToken: string): Promise<API.GetDealsResp> {
    try {
      const response = await ApiService.get<API.GetDealsResp>("/api/deals", accessToken);
      if (response?.data?.data?.success && response?.data?.data?.deals) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting customer");
      }
    } catch (err) {
      throw ApiService.errorHandler(err);
    }
  }
  static async getReport(accessToken: string , reportType: string | null, reportReq: API.ReportReq | null) {
    try {
      let params = {type: reportType}
      if(reportReq) params = {...params , ...reportReq}
      const response = await ApiService.post<API.ReportReq, API.ReportResp>("/api/reports", params , accessToken);
      if (response?.data?.data?.success && response?.data?.data?.report) {
        return response.data.data;
      } else {
        throw new Error("Incorrect search parameters");
      }
    } catch (err) {
      ApiService.errorHandler(err);
    }
  }
  static async getUserActivity(accessToken: string, reportReq: API.ActivityReportReq) {
    try {
      const response = await ApiService.post<API.ActivityReportReq, API.ActivityReportResp>("/api/reports/activity", reportReq , accessToken);
      return response.data.data
    } catch (err) {
      ApiService.errorHandler(err);
    }
  }
  static async excludeUsers(accessToken: string, excludeReq: API.ExcludeReq) {
    try {
      const response = await ApiService.post<API.ExcludeReq, API.ExcludeResp>("/api/reports/activity/exclude", excludeReq , accessToken);
      return response.data.data
    } catch (err) {
      ApiService.errorHandler(err);
    }
  }
  static async createDeal(accessToken: string , dealReq: API.DealReq) {
    try {
      const response = await ApiService.post<API.DealReq, API.DealResp>("/api/create-deal", dealReq , accessToken);
      if (response?.data?.data?.success && response?.data?.data?.deal) {
        return response.data.data;
      } else {
        throw new Error("Incorrect search parameters");
      }
    } catch (err) {
      ApiService.errorHandler(err);
    }
  }
  static async createUser(accessToken: string , registerReq: API.RegisterReq) {
    try {
      const response = await ApiService.post<API.RegisterReq, API.RegisterResp>("/api/users/create", registerReq , accessToken);
      if (response?.data?.data?.success) {
        return response.data.data?.success;
      } else {
        throw new Error("Incorrect search parameters");
      }
    } catch (err) {
      ApiService.errorHandler(err);
    }
  }
  static async updateCard(accessToken: string, updateCardReq: API.UpdateCardReq): Promise<API.UpdateCardResp> {
    try {
      const response = await ApiService.post<API.UpdateCardReq, API.UpdateCardResp>("/subscriptions/update-card", updateCardReq, accessToken);
      if (response?.data?.data?.success) {
        return {success: response?.data?.data.success};
      } else {
        throw new Error("Error updating card");
      }
    } catch (err) {
      throw ApiService.errorHandler(err);
    }
  }
}