import { Service } from "./service";

export class UserService extends Service {
  static async signIn(credentials: API.LoginReq) {
    try {
      const response = await UserService.post<API.LoginReq, API.LoginResp>("/api/login", credentials);
      if (response?.data?.data?.accessToken && response.data.data.user) {
        return response?.data?.data;
      } else {
        throw new Error("Wrong login response");
      }
    } catch (err) {
      UserService.errorHandler(err);
    }
  }
  static async initPasswordReset(initPasswordResetData: API.InitPasswordResetReq): Promise<API.InitPasswordResetResp> {
    try {
      const response = await UserService.post<API.InitPasswordResetReq, API.InitPasswordResetResp>("/api/init-password-reset", initPasswordResetData);
      if (response?.data?.data?.success) {
        return response?.data.data;
      } else {
        throw new Error("Error password reset");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async passwordReset(passwordResetData: API.PasswordResetReq): Promise<API.PasswordResetResp> {
    try {
      const response = await UserService.post<API.PasswordResetReq, API.PasswordResetResp>("/api/password-reset", passwordResetData);
      if (response?.data?.data?.success) {
        return response?.data.data;
      } else {
        throw new Error("Error password reset");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async getUserdata(accessToken: string): Promise<API.GetUserdataResp> {
    try {
      const response = await UserService.get<API.GetUserdataResp>("/api/get-userdata", accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting userdata");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async getDiscount(accessToken: string, getDiscountReq: API.GetDiscountReq): Promise<API.DiscountData> {
    try {
      const response = await UserService.post<API.GetDiscountReq, API.GetDiscountResp>("/subscriptions/get-discount", getDiscountReq, accessToken);
      const data = response?.data?.data;
      if (data && data?.success && data?.discount) {
        return data.discount;
      } else {
        throw new Error("Coupon applying error");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
}