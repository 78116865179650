import { defineStore } from 'pinia';
import { router } from '@/router';
import { UserService } from '../services/user';
import { useUserStore } from './user';
import { ApiService } from '@/services/api';
import { useConfigStore } from './config';

export interface AuthState {
    accessToken: string | null;
}

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => {
        return {
            accessToken: null
        };
    },
    actions: {
        async login(credentials: API.LoginReq) {
            try {
                const userData = await UserService.signIn(credentials);
                if (userData?.accessToken) {
                    this.loginSuccess(userData.accessToken, userData.user);
                    await this.getMetadata(userData.accessToken);
                } else {
                    this.loginFailure();
                }
            } catch (err) {
                this.loginFailure();
                throw err;
            }
        },
        async initialize() {
            try {
                const accessToken = this.getToken();
                if (accessToken) {
                    const response = await UserService.getUserdata(accessToken);
                    if (response.success && response.userdata) {
                        this.loginSuccess(accessToken, response.userdata);
                        await this.getMetadata(accessToken);
                    } else {
                        this.loginFailure();
                    }
                } else {
                    this.loginFailure();
                }
            } catch (err) {
                this.loginFailure();
            }
        },
        async getMetadata(accessToken: string) {
          // Get user list
          const userList = await ApiService.getUsers(accessToken);
          if (userList.success && userList.users) {
            const configStore = useConfigStore();
            configStore.userList = userList.users;
          }
          // Get deal list
          const dealList = await ApiService.getDeals(accessToken);
          if (dealList.success && dealList.deals) {
            const configStore = useConfigStore();
            configStore.dealList = dealList.deals;
          }
        },
        logout() {
            this.removeToken();
            const userStore = useUserStore();
            userStore.clearAll();
            router.push("login");
        },
        loginSuccess(accessToken: string, userData: API.UserData) {
            this.setToken(accessToken);
            const userStore = useUserStore();
            userStore.setAll(userData);
        },
        loginFailure() {
            this.removeToken();
            const userStore = useUserStore();
            userStore.clearAll();
        },
        setToken(accessToken: string) {
            localStorage.setItem("accessToken", accessToken);  //todo obtain token from secure place
            this.accessToken = accessToken;
        },
        removeToken() {
            localStorage.removeItem("accessToken");  //todo obtain token from secure place
            this.accessToken = null;
        },
        getToken() {
            return localStorage.getItem('accessToken'); //todo obtain token from secure place
        }
    }
});

export function getAccessToken() {
    const auth = useAuthStore();
    if (!auth.accessToken) {
        throw new Error("No access token");
    }
    return auth.accessToken;
}